import {
    Stack,
    Flex,
    Text,
    Link,
    VStack,
    useBreakpointValue,
    Container,
    useColorModeValue,
    Avatar,
    Badge,
    Box,
    Heading,
} from '@chakra-ui/react';
import { Smile } from "../Config/icon";
// You can use images this way
// import Dhruvesh from "../assets/images/devs/dhruvesh.jpeg";
// import Dharmesh from "../assets/images/devs/dharmesh.jpg";

export default function About() {
    return (
        <>
            <Flex
                w={'full'}
                h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                backgroundImage={
                    'url(https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
                }
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                <VStack
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                    <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm: 'sm' })}>
                            WE ARE HEROS OF
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.1}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                            Design, Development, and Identify your product.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm: 'xs' })}>
                            Leading IT company with soild design and Development expertise.
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW={'7xl'} py={12}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={'1xl'}>WELL HELLO THERE</Heading>
                    <Heading fontSize={'3xl'}>We’re Triadic Solution – An IT solution company focused on design & development.</Heading>
                    <Text mb={2} color={'gray.600'} fontSize={'xl'}>
                        We are three individuals, an enthusiast for IT based solutions – technology makes us curious. We believe in the transformative power of logic, design and development and their ability to simplify business growth, elevate experiences, engage and inspire people everywhere. Let’s create something beautiful together.
                    </Text>
                    <Flex justifyContent={"center"}>
                        <Smile size="18px" />
                    </Flex>
                </Stack>
                <Flex mt={10} justifyContent={"center"} mb={10}>
                    <Stack>
                        <Heading>Our Team</Heading>
                        <hr />
                    </Stack>
                </Flex>
                <Stack py={6} display={"flex"} spacing={10} justifyContent={"center"} alignItems={"center"} direction={useBreakpointValue({ sm: "column", lg: "row" })}>
                    <Box
                        maxW={'320px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}>
                        <Avatar
                            size={'xl'}
                            // src={Dharmesh}
                            alt={'Dharmesh'}
                            mb={4}
                            pos={'relative'}
                        />
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            Dharmesh Joshi
                        </Heading>
                        <Text
                            textAlign={'center'}
                            color={useColorModeValue('gray.700', 'gray.400')}
                            px={3}>
                            HTML, CSS, bootstrap framework, JavaScript, Custom Jquery{' '}
                            <Link href={'#'} color={'blue.400'}>
                                #Experience 
                            </Link>{' '}
                             - 5 Years 
                        </Text>

                        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #wake
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #code
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #sleep
                            </Badge>
                        </Stack>
                    </Box>

                    <Box
                        maxW={'320px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}>
                        <Avatar
                            size={'xl'}
                            // src={Dhruvesh}
                            alt={'Dhruvesh'}
                            mb={4}
                            pos={'relative'}                            
                        />
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            Dhruvesh Gajjar
                        </Heading>
                        <Text
                            textAlign={'center'}
                            color={useColorModeValue('gray.700', 'gray.400')}
                            px={3}>
                            PHP, Cake-PHP, CodeIgniter, Laravel, React; {' '}
                            <Link href={'#'} color={'blue.400'}>
                                #Experience
                            </Link>{' '}
                            - 5 Years
                        </Text>

                        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #code
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #debug
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #repeate
                            </Badge>
                        </Stack>
                    </Box>
                    <Box
                        maxW={'320px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}>
                        <Avatar
                            size={'xl'}
                            // src={require("../assets/images/devs/sameer.jpeg")}
                            alt={'Sameer'}
                            mb={4}
                            pos={'relative'}
                        />
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            Sameer Sherathiya
                        </Heading>
                        <Text
                            textAlign={'center'}
                            color={useColorModeValue('gray.700', 'gray.400')}
                            px={3}>
                            QA Tester - Manual and Automation;  {' '}
                            <Link href={'#'} color={'blue.400'}>
                                #Experience
                            </Link>{' '}
                            - 2 Years
                        </Text>

                        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #Bug
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #Issue
                            </Badge>
                            <Badge
                                px={2}
                                py={1}
                                bg={useColorModeValue('gray.50', 'gray.800')}
                                fontWeight={'400'}>
                                #Exception
                            </Badge>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    );
}